<template>
  <div>
    <v-simple-table
      v-if="deal.products && deal.products.length > 0"
      style="border-radius: 0"
      data-testid="table-deal-products"
    >
      <template v-slot:default>
        <tbody>
          <tr
            style="width: 100%"
            v-for="product in deal.products"
            :key="product._key"
          >
            <td
              class="text-left"
              style="width: 320px"
              data-testid="hs-product-name"
            >
              {{ getBundleName(product) }}
            </td>
            <td
              class="text-left"
              v-if="isSuperUser"
              style="width: 150px; overflow-wrap: break-word; max-width: 150px"
              data-testid="hs-product-company"
            >
              {{ deal.company ? deal.company.name : "" }}
            </td>
            <td
              class="text-left"
              style="width: 200px; overflow-wrap: break-word; max-width: 200px"
              data-testid="hs-product-engine"
            >
              {{ product.engine ? product.engine.name : "" }}
            </td>
            <td
              class="text-left"
              style="width: 250px; overflow-wrap: break-word; max-width: 250px"
              data-testid="hs-contact-email"
            >
              {{ product.hs_contact_email ? product.hs_contact_email : "" }}
            </td>
            <td
              class="text-left"
              style="width: 120px; overflow-wrap: break-word; max-width: 120px"
              data-testid="hs-product-start-date"
            >
              {{ parseInt(product.properties.product_start_date) | dateFormat }}
            </td>
            <td
              class="text-left"
              style="width: 120px; overflow-wrap: break-word; max-width: 120px"
              data-testid="hs-product-end-date"
            >
              {{ parseInt(product.properties.product_end_date) | dateFormat }}
            </td>
            <td
              class="text-center"
              style="width: 120px; overflow-wrap: break-word; max-width: 120px"
              data-testid="product-connections"
            >
              {{
                product.properties.connections === "-1"
                  ? "&infin;"
                  : product.properties.connections
              }}
            </td>
            <td class="text-left" data-testid="product-status">
              {{ product.status }}
            </td>
            <td class="text-left" style="width: 80px">
              <div class="text-center d-flex align-center justify-space-around">
                <v-tooltip
                  top
                  v-if="
                    product.engine &&
                    product.status !== 'NEW' &&
                    allowedProdTypesToAssign.includes(product.product_type)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      :color="Pallette.actionButtons.edit"
                      v-on="on"
                      class="mx-1"
                      @click="reassignEngine(product)"
                      data-testid="product-reassign-engine"
                    >
                      <v-icon>mdi-monitor-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Reassign engine</span>
                </v-tooltip>

                <!-- We show this button only for editing white list for BUNDLE_INTERNAL_UNLIM -->
                <v-tooltip
                  top
                  v-if="
                    productRequireIpsWhitelist(product) &&
                    product.status !== 'NEW' &&
                    allowedProdTypesToAssign.includes(product.product_type)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      :color="Pallette.actionButtons.edit"
                      v-on="on"
                      class="mx-1"
                      @click="productRegister(product)"
                      data-testid="manage-bundle"
                    >
                      <v-icon>mdi-license</v-icon>
                    </v-btn>
                  </template>
                  <span>Manage bundle</span>
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    product.status === 'NEW' &&
                    allowedProdTypesToAssign.includes(product.product_type)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      :color="Pallette.actionButtons.generate"
                      v-on="on"
                      class="mx-1"
                      @click="productRegister(product)"
                      data-testid="product-assign-engine"
                    >
                      <v-icon>add_to_queue</v-icon>
                    </v-btn>
                  </template>
                  <span>Assign engine</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <v-alert text color="info">
        <div>No products</div>
      </v-alert>
    </div>
    <DealProductRegisterDialog
      :dialog.sync="dialogRegisterProduct"
      :product="currentProduct"
    />
    <ProductEngineAssignDialog
      :dialog.sync="dialogReassignEngine"
      :product="currentProduct"
    />
  </div>
</template>

<script>
/**
 * Component for list of products in table assigned to deal
 */
import { mapGetters } from "vuex"
import DealProductRegisterDialog from "./DealProductRegisterDialog"
import ProductEngineAssignDialog from "./ProductEngineAssignDialog"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    deal: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  components: {
    DealProductRegisterDialog,
    ProductEngineAssignDialog,
  },

  mixins: [StoreMixin],

  data() {
    return {
      dialogRegisterProduct: false,
      dialogReassignEngine: false,
      currentProduct: null,
      allowedProdTypesToAssign: [
        "BUNDLE_EXTERNAL_5",
        "BUNDLE_EXTERNAL_10",
        "BUNDLE_EXTERNAL_UNLIM",
        "BUNDLE_INTERNAL_UNLIM",
        "BUNDLE_UPGRADE_10",
        "BUNDLE_UPGRADE_UNLIM",
      ],
    }
  },

  methods: {
    productRegister(product) {
      this.currentProduct = product
      this.currentProduct.company = this.deal.company
      setTimeout(() => {
        this.dialogRegisterProduct = true
      }, 200)
    },
    reassignEngine(product) {
      this.currentProduct = product
      this.currentProduct.company = this.deal.company
      setTimeout(() => {
        this.dialogReassignEngine = true
      }, 200)
    },
    productRequireIpsWhitelist(product) {
      return product?.product_type === "BUNDLE_INTERNAL_UNLIM"
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),
  },
}
</script>
