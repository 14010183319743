<template>
  <div>
    <v-card dark id="deals-list">
      <v-toolbar>
        <v-card-title>Deals</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/store/deal/create"
          data-testid="btn-deal-create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <DataTableExtended
          :headers="headersTable"
          :items="dealsListCalc"
          :single-expand="true"
          :expanded.sync="dealsListCalc"
          item-key="name"
          :show-expand="false"
          class="elevation-0 border table-deals"
          inset
          :calculate-widths="true"
          :loading="showTableLoader"
          :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
          sortable
          :multi-sort="false"
          :server-items-length="dealsCount"
          v-on:init-table-data="getDealsList"
          ref="refTableDeals"
          keep="_key,name,hubspot_key"
          data-testid="table-deals"
        >
          <template v-slot:top>
            <v-row class="pa-3 d-flex align-center">
              <v-col cols="12" md="6"> </v-col>
              <v-col
                cols="12"
                md="3"
                class="text-right"
                v-if="$can('read', 'Superadmin')"
              >
                <CompanyFilter
                  :hideDetails="true"
                  v-on:update:selected="onCompanyFilterChange($event)"
                  data-testid="filter-company"
                />
              </v-col>
              <v-col
                cols="12"
                :offset-md="$can('read', 'Superadmin') ? 0 : 3"
                md="3"
                class="text-right"
              >
                <v-autocomplete
                  v-model="filterStatus"
                  :items="productStatuses"
                  item-text="name"
                  item-value="id"
                  label="Filter by status"
                  outlined
                  hide-details
                  clearable
                  data-testid="filter-status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div data-testid="name">
              {{ getDealName(item) }}
            </div>
          </template>

          <template v-slot:[`item.data-table-expand`]="{ item }">
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-tooltip top v-if="$can('delete', 'Superadmin')">
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="deleteDeal(item)"
                    class="mx-1"
                    data-testid="btn-delete-deal"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete deal</span>
              </v-tooltip>

              <v-tooltip top v-if="$can('update', 'Superadmin')">
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.edit"
                    v-on="on"
                    :to="`/store/deal/edit/${item.deal._key}`"
                    class="mx-1"
                    data-testid="btn-edit-deal"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit deal</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <DealProducts :deal="item" />
            </td>
          </template>
          Product Bundle Upgrade Unlimited Live** was registered successfully.
        </DataTableExtended>
      </v-card-text>
    </v-card>
    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
/**
 * Component for list of deals
 */
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import DataTableExtended from "@/components/table/DataTableExtended"
import DealProducts from "./DealProducts"
import CompanyFilter from "@/components/filters/CompanyFilter"
import StoreMixin from "@/mixins/store/common.js"
import _ from "lodash"

export default {
  components: {
    Confirm,
    DataTableExtended,
    DealProducts,
    CompanyFilter,
  },

  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          width: 320,
        },
        { text: "Engine", width: 200, sortable: false },
        { text: "Email", width: 250, sortable: false },
        { text: "Start date", width: 120, sortable: false },
        { text: "End date", width: 120, sortable: false },
        { text: "Connections", width: 120, sortable: false },
        { text: "Status", sortable: false },
        { text: "", value: "data-table-expand", sortable: false, width: 80 },
      ],
      filterByCompany: null,
      filterStatus: null,
    }
  },

  mixins: [StoreMixin],

  watch: {
    filterByCompany: function () {
      this.getDealsList()
    },
    filterStatus: function () {
      this.getDealsList()
    },
  },

  mounted: function () {
    let self = this
    self.$store.dispatch("getProductStatuses")
    self.$root.$on("refreshDeals", (params) => {
      self.getDealsList()
    })
  },

  methods: {
    onCompanyFilterChange(event) {
      this.filterByCompany = event
    },
    getDealsList: function () {
      let self = this
      let getParams = self.$refs.refTableDeals.getTableServerParams()
      if (self.filterByCompany && self.isSuperUser)
        getParams.hs_company_id = self.filterByCompany
      if (self.filterStatus) getParams.status = self.filterStatus
      self.$store.dispatch("getDealsList", { params: getParams })
    },
    deleteDeal(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this deal?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("deleteDeal", item)
              .then((response) => {
                if (response.data.status === "success") self.getDealsList()
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
    },
    editDeal(item) {
      this.activeItem = _.cloneDeep(item)
      this.editedIndex = item._key
    },
  },

  computed: {
    ...mapGetters({
      dealsList: "dealsList",
      dealsCount: "dealsCount",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      productStatuses: "productStatuses",
    }),

    headersTable() {
      let headersTable = this.headers
      if (this.isSuperUser) {
        let companyHeader = {
          text: "Company",
          align: "left",
          width: 150,
          sortable: false,
        }
        headersTable.splice(1, 0, companyHeader)
      }
      return headersTable
    },
    dealsListCalc: {
      get() {
        return this.$store.getters.dealsList
      },
      set() {},
    },
  },
}
</script>

<style lang="scss">
.table-deals {
  .v-data-table__expanded.v-data-table__expanded__row td {
    background-color: #272727;
  }

  .v-data-table__expanded {
    &.v-data-table__expanded__content td {
      &:first-child {
        padding-left: 40px;
      }

      background-color: #424242;
    }
  }
}
</style>
