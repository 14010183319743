<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    content-class="dialog-product-register"
    attach="#deals-list"
  >
    <v-form
      data-vv-scope="form-product-registered"
      key="bundleRegisteredCreateUpdate"
      data-testid="form-product-registered"
    >
      <v-card>
        <v-card-title>
          <span class="h6">Register product {{ bundleName }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  v-if="
                    errors.has('form-product-registered.general_error_field')
                  "
                >
                  <v-alert type="error" text>
                    {{
                      errors.first(
                        "form-product-registered.general_error_field"
                      )
                    }}
                  </v-alert>
                </v-col>

                <v-col cols="12" sm="12">
                  <SelectEnginesAsync
                    v-model="activeProductRegistered.engine"
                    outlined
                    label="Engine"
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect(`form-product-registered.engine`)
                    "
                    data-vv-name="engine"
                    data-vv-validate-on="change"
                    field="engine"
                    :menu-props="{ maxHeight: 304 }"
                    :disabled="isEngineSelectDisabled"
                    :customFilters="customFilterEngines"
                    data-testid="engine"
                  />
                </v-col>

                <!--                <v-col cols="12" md="12" v-if="editedIndex !== -1">-->
                <!--                  <v-menu-->
                <!--                    ref="menu"-->
                <!--                    v-model="menu"-->
                <!--                    :close-on-content-click="false"-->
                <!--                    :return-value.sync="activeProductRegistered.expiration_date"-->
                <!--                    transition="scale-transition"-->
                <!--                    offset-y-->
                <!--                    min-width="auto"-->
                <!--                  >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                      <v-text-field-->
                <!--                        v-model="activeProductRegistered.expiration_date"-->
                <!--                        label="Expiration date"-->
                <!--                        prepend-icon="mdi-calendar"-->
                <!--                        readonly-->
                <!--                        v-bind="attrs"-->
                <!--                        v-on="on"-->
                <!--                        outlined-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker-->
                <!--                      v-model="activeProductRegistered.expiration_date"-->
                <!--                      no-title-->
                <!--                      scrollable-->
                <!--                    >-->
                <!--                      <v-spacer></v-spacer>-->
                <!--                      <v-btn-->
                <!--                        text-->
                <!--                        color="primary"-->
                <!--                        @click="menu = false"-->
                <!--                      >-->
                <!--                        Cancel-->
                <!--                      </v-btn>-->
                <!--                      <v-btn-->
                <!--                        text-->
                <!--                        color="primary"-->
                <!--                        @click="$refs.menu.save(activeProductRegistered.expiration_date)"-->
                <!--                      >-->
                <!--                        OK-->
                <!--                      </v-btn>-->
                <!--                    </v-date-picker>-->
                <!--                  </v-menu>-->
                <!--                </v-col>-->

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="ip-whitelist-container"
                  v-if="productRequireIpsWhitelist"
                >
                  <v-combobox
                    v-model="activeProductRegistered.white_list"
                    :items="items"
                    chips
                    outlined
                    label="IP whitelist"
                    multiple
                    prepend-icon="mdi-plus-circle"
                    @click:prepend="dialogWhitelistIps = true"
                    append-icon="false"
                    readonly
                    class="py-0"
                    height="44"
                    data-testid="white-list"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeIP(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <AddSingleTextDialog
      :dialog.sync="dialogWhitelistIps"
      @save-field="addNewIp"
      title="Add IP to whitelist"
      field="IP"
      rules="required|validIp"
    ></AddSingleTextDialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/ip/ip-validator"
import GeneralMixin from "@/mixins/general.js"
import AddSingleTextDialog from "@/components/commonComponents/AddSingleTextDialog"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    dialogWhitelistIps: false,
    items: [],
    menu: false,
    selectedEngine: null,
    isNewRegistry: false,
  }),

  components: {
    AddSingleTextDialog,
    SelectEnginesAsync,
  },

  mixins: [GeneralMixin, StoreMixin],

  watch: {
    dialog: function (val) {
      if (val) {
        this.isNewRegistry = this.product.engine ? false : true
        this.initData()
      }
    },
  },

  beforeDestroy() {
    this.resetFormData()
  },

  methods: {
    resetFormData() {
      let self = this
      return new Promise((resolve) => {
        self.$validator.errors.clear("form-product-registered")
        self.$store.dispatch("resetProductRegisteredItem").then(() => {
          resolve()
        })
      })
    },
    initData() {
      let self = this
      self.$store.dispatch("saveLoaderAction", true)
      self.resetFormData().then(() => {
        self.getItemData().then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    },
    removeIP(item) {
      this.activeProductRegistered.white_list.splice(
        this.activeProductRegistered.white_list.indexOf(item),
        1
      )
      this.activeProductRegistered.white_list = [
        ...this.activeProductRegistered.white_list,
      ]
    },
    addNewIp(item) {
      this.activeProductRegistered.white_list.push(item)
    },
    getItemData() {
      return this.$store.dispatch("getProductRegisteredItem", {
        engine: this.product?.engine,
        white_list: this.product?.properties?.white_list || [],
      })
    },
    createRegisteredProduct(formData) {
      let self = this
      self.$store
        .dispatch("createProductRegistered", formData)
        .then(({ data }) => {
          let params = {
            text: `Product <strong>${this.bundleName}</strong> was registered successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
            self.$root.$emit("refreshDeals")
          })
        })
        .catch(({ response }) => {
          console.error("Error on createRegisteredProduct", response)
          self.handleValidationErrors(response, "form-product-registered")
        })
    },
    editRegisteredProduct(formData) {
      let self = this
      self.$store
        .dispatch("editProductRegistered", formData)
        .then(({ data }) => {
          let params = {
            text: `Product <strong>${this.bundleName}</strong> registry was edited successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
            self.$root.$emit("refreshDeals")
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-product-registered")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-product-registered")
      self.$validator.validateAll("form-product-registered").then((result) => {
        if (result) {
          let formData = {
            data: {},
          }
          if (self.isNewRegistry) {
            formData.data.product_key = self.product._key
          }
          if (self.productRequireIpsWhitelist) {
            formData.data.white_list =
              self.activeProductRegistered.white_list || []
          }
          formData["slug"] = self.activeProductRegistered.engine._key
          if (!this.isNewRegistry) {
            self.editRegisteredProduct(formData)
          } else {
            self.createRegisteredProduct(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: self.pathRedirect })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    closeDialog() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$emit("update:dialog", false)
        this.resetFormData()
        resolve()
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeProductRegistered: "activeProductRegistered",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),
    productRequireIpsWhitelist() {
      return this?.product?.product_type === "BUNDLE_INTERNAL_UNLIM"
    },
    pathRedirect: function () {
      return this.isSuperUser ? "/store/bundles/registry" : "/store/bundles"
    },
    isEngineSelectDisabled() {
      return !this.isNewRegistry && this.activeProductRegistered?.engine?._key
        ? true
        : false
    },
    bundleName() {
      return this.getBundleName(this.product)
    },
    customFilterEngines() {
      let filters = {}
      if (this.product?.company?._key) {
        filters.company_key = this.product.company._key
      }
      return filters
    },
  },
}
</script>

<style lang="scss">
.ip-whitelist-container {
  overflow-x: hidden;
}
.dialog-product-register .v-card__title {
  word-break: break-word;
}
</style>
