<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    content-class="dialog-product-engine-assign"
    attach="#deals-list"
  >
    <v-form
      data-vv-scope="form-product-registered"
      key="form-product-registered"
      data-testid="form-product-registered"
    >
      <v-card>
        <v-card-title>
          <span class="h6">Reassign engine for: {{ bundleName }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  v-if="errors.has('form-product-engine.general_error_field')"
                >
                  <v-alert type="error" text>
                    {{
                      errors.first("form-product-engine.general_error_field")
                    }}
                  </v-alert>
                </v-col>

                <v-col cols="12" sm="12">
                  <SelectEnginesAsync
                    v-model="activeProductRegistered.engine"
                    outlined
                    label="Engine"
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect(`form-product-engine.engine_key`)
                    "
                    data-vv-name="engine"
                    data-vv-validate-on="change"
                    field="engine"
                    :menu-props="{ maxHeight: 304 }"
                    :customFilters="customFilterEngines"
                    data-testid="engine"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },
  },

  data: () => ({}),

  components: {
    SelectEnginesAsync,
  },

  mixins: [GeneralMixin, StoreMixin],

  watch: {
    dialog: function (val) {
      if (val) {
        this.initData()
      }
    },
  },

  beforeDestroy() {
    this.resetFormData()
  },

  methods: {
    resetFormData() {
      let self = this
      return new Promise((resolve) => {
        self.$validator.errors.clear("form-product-engine")
        self.$store.dispatch("resetProductRegisteredItem").then(() => {
          resolve()
        })
      })
    },
    initData() {
      let self = this
      self.$store.dispatch("saveLoaderAction", true)
      self.resetFormData().then(() => {
        self.getItemData().then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    },
    getItemData() {
      return this.$store.dispatch("getProductRegisteredItem", {
        engine: this.product?.engine,
      })
    },
    reassignEngine(formData) {
      let self = this
      self.$store
        .dispatch("editProduct", formData)
        .then(({ data }) => {
          let params = {
            text: `Engine for <strong>${this.bundleName}</strong> was reassigned successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
            self.$root.$emit("refreshDeals")
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-product-engine")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-product-engine")
      self.$validator.validateAll("form-product-engine").then((result) => {
        if (result) {
          let formData = {
            data: {
              engine_key: self.activeProductRegistered.engine._key,
            },
          }
          formData["slug"] = self.product._key
          self.reassignEngine(formData)
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: self.pathRedirect })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    closeDialog() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$emit("update:dialog", false)
        this.resetFormData()
        resolve()
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeProductRegistered: "activeProductRegistered",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),
    pathRedirect: function () {
      return this.isSuperUser ? "/store/bundles/registry" : "/store/bundles"
    },
    bundleName() {
      return this.getBundleName(this.product)
    },
    customFilterEngines() {
      let filters = {}
      if (this.product?.company?._key) {
        filters.company_key = this.product.company._key
      }
      return filters
    },
  },
}
</script>

<style lang="scss">
.ip-whitelist-container {
  overflow-x: hidden;
}

.dialog-product-register .v-card__title {
  word-break: break-word;
}
</style>
